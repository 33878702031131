<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <!-- <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col> -->
      <b-col class="text-center">
        CHÚC BẠN MỘT NGÀY LÀM VIỆC VUI VẺ
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

// import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
// import EcommerceStatistics from './EcommerceStatistics.vue'
import AnalyticsStatistics from '../analytics/AnalyticsStatistics.vue'
import AnalyticsCompanyChart from '../analytics/AnalyticsCompanyChart.vue'
import AnalyticsCompanyPercentChart from '../analytics/AnalyticsCompanyPercentChart.vue'
import AnalyticsWorkerPercentChart from '../analytics/AnalyticsWorkerPercentChart.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    AnalyticsStatistics,
    AnalyticsCompanyChart,
    AnalyticsCompanyPercentChart,
    AnalyticsWorkerPercentChart,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      data: {
        congratulations: {},
      },
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ name: 'auth-login' })
    }
    // const userData = JSON.parse(localStorage.getItem('userData'))
    // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    // data

    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data
        const userDataAuth = JSON.parse(localStorage.getItem('userData'))
        this.data.congratulations.name = userDataAuth.fullName.split(' ')[0] || userDataAuth.username
      })

    // this.$http.get('/Account/accountInfo')
    //   .then(response => {
    //     localStorage.setItem('userData', JSON.stringify(response.data))
    //   })
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
