<template>
  <b-card
    v-if="data"
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <h4 class="font-weight-bolder mb-1">
      Thành phần các loại hình doanh nghiệp
    </h4>
    <!-- chart -->
    <vue-apex-charts
      :options="statisticsProfit.chartOptions"
      :series="data.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {
            type: 'donut',
          },
          plotOptions: {
            pie: {
              donut: {
                size: '45%',
              },
            },
          },
          labels: ['DN Nhà nước', 'Công ty TNHH', 'Công ty cổ phần', 'DN Tư nhân', 'DN FDI', 'Hợp tác xã', 'Công ty hợp doanh', 'Khác'],
        },
      },
    }
  },
}
</script>
