<template>
  <b-card
    v-if="data"
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <h4 class="font-weight-bolder mb-1">
      Số lượng doanh nghiệp trong 10 năm gần đây
    </h4>
    <!-- chart -->
    <vue-apex-charts
      :options="statisticsProfit.chartOptions"
      :series="data.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {
            type: 'line',
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            // strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.success],
          // markers: {
          //   size: 2,
          //   colors: $themeColors.warning,
          //   strokeColors: $themeColors.warning,
          //   strokeWidth: 2,
          //   strokeOpacity: 1,
          //   strokeDashArray: 0,
          //   fillOpacity: 1,
          //   discrete: [
          //     {
          //       seriesIndex: 0,
          //       dataPointIndex: 5,
          //       fillColor: '#ffffff',
          //       strokeColor: $themeColors.warning,
          //       size: 5,
          //     },
          //   ],
          //   shape: 'circle',
          //   radius: 2,
          //   hover: {
          //     size: 3,
          //   },
          // },
          xaxis: {
            categories: [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020],
            show: true,
          },
          yaxis: {
            show: true,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
}
</script>
